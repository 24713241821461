import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Input from "common/components/Input";
import Container from "common/components/UI/Container";
import ContactFromWrapper from "./contact.style";
const ContactSection = ({
							mailChimpUrl,
							sectionWrapper,
							row,
							contactForm,
							secTitleWrapper,
							secHeading,
							secText,
							button,
							buttonText,
							note
						}) => {
	const [email, setEmail] = useState("")
	const [userMessage, setUserMessage] = useState("")
	const [error, setError] = useState()
	return (
		<Box { ...sectionWrapper }>
			<Container>
				<Box { ...secTitleWrapper }>
					<Text content="CONTACT US" { ...secText }/>
					<Heading
						content="Are you Interested to meet with us?"
						{ ...secHeading }
					/>
				</Box>
				<Box { ...row }>
					<Box { ...contactForm }>
						<MailchimpSubscribe
							url={mailChimpUrl}
							render={({ subscribe, status, message }) => (
								<Fragment>
									<ContactFromWrapper>
										<Input
											inputType="email"
											placeholder="Email address"
											iconPosition="right"
											isMaterial={ false }
											className="email_input"
											onChange={(value) => {
												setEmail(value)
												setError()
											}}
											aria-label="email"
										/>
									</ContactFromWrapper>
									<ContactFromWrapper>
										<Input
											inputType="textarea"
											placeholder="Tell us briefly about your ventures you we can provide a better service to you"
											iconPosition="right"
											isMaterial={ false }
											onChange={(value) => {
												setUserMessage(value)
											}}
											className="email_input"
											aria-label="ventures"
										/>
									</ContactFromWrapper>
									{
										error && <Text
											{ ...note }
											content={error}
										/>
									}
									{
										message && <Text
											{ ...note }
											content={message}
										/>
									}
									<ContactFromWrapper>
										<Button
											title={buttonText ?? "SEND MESSAGE"}
											onClick={() => {
												if(email.indexOf("@") > -1) {
													console.log("subscribe");
													subscribe({
														EMAIL: email,
														PAGE_URL: window.location.href,
														MESSAGE: userMessage
													})
												}
												else {
													setError("Please enter a valid email")
												}
											}}
											{ ...button }
										/>
									</ContactFromWrapper>
								</Fragment>
							)}
						>
						</MailchimpSubscribe>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};
ContactSection.propTypes = {
	sectionWrapper: PropTypes.object,
	secTitleWrapper: PropTypes.object,
	mailChimpUrl: PropTypes.string,
	row: PropTypes.object,
	contactForm: PropTypes.object,
	secHeading: PropTypes.object,
	secText: PropTypes.object,
	button: PropTypes.object,
	note: PropTypes.object
};
ContactSection.defaultProps = {
	sectionWrapper: {
		id: "contact_section",
		as: "section",
		pt: ["0px", "10px", "20px", "80px"],
		pb: ["0px", "0px", "0px", "0px", "80px"],
		bg: "#f9fbfd"
	},
	secTitleWrapper: {
		mb: ["45px", "50px", "60px"]
	},
	secText: {
		as: "span",
		display: "block",
		textAlign: "center",
		fontSize: `${ 2 }`,
		letterSpacing: "0.15em",
		fontWeight: `${ 6 }`,
		color: "primary",
		mb: `${ 3 }`
	},
	secHeading: {
		textAlign: "center",
		fontSize: [`${ 6 }`, `${ 8 }`],
		fontWeight: "400",
		color: "headingColor",
		letterSpacing: "-0.025em",
		mb: `${ 0 }`
	},
	row: {
		flexBox: true,
		justifyContent: "center"
	},
	contactForm: {
		width: [1, 1, 1, 1 / 2]
	},
	button: {
		type: "button",
		fontSize: `${ 2 }`,
		fontWeight: "600",
		borderRadius: "4px",
		pl: "22px",
		pr: "22px",
		colors: "primaryWithBg",
		height: `${ 4 }`
	},
	note: {
		fontSize: ["13px", "14px", "15px", "15px", "15px"],
		color: "#5d646d",
		lineHeight: "1.87",
		textAlign: "center"
	}
};
export default ContactSection;
