import React, { Fragment } from "react";
import Head from "next/head";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { saasTheme } from "common/theme/saas";
import ResetCSS from "common/assets/css/style";
import { ContentWrapper, GlobalStyle } from "containers/Saas/saas.style";
import Navbar from "containers/Saas/Navbar";
import BannerSection from "containers/Saas/BannerSection";
import FeatureSection from "containers/Saas/FeatureSection";
import VisitorSection from "containers/Saas/VisitorSection";
import ServiceSection from "containers/Saas/ServiceSection";
import Footer from "containers/Saas/Footer";
import PricingSection from "containers/Saas/PricingSection";
import TrialSection from "containers/Saas/TrialSection";
import TimelineSection from "containers/Saas/TimelineSection";
import TestimonialSection from "containers/Saas/TestimonialSection";
import PartnerSection from "containers/Saas/PartnerSection";
import { DrawerProvider } from "common/contexts/DrawerContext";
import FaqSection from "containers/Saas/FaqSection";
import TeamParallax from "../../common/assets/image/narkuma/team-parallax.png";
import MWO from "../../common/assets/image/narkuma/mwo-logo.png";
import Fossa from "../../common/assets/image/narkuma/fossa.png";
import Myr from "../../common/assets/image/narkuma/myr.png";
import Telegram from "../../common/assets/image/narkuma/telegram.jpg";
import Narkuma from "../../common/assets/image/narkuma/narkuma.png";
import NewsletterSection from "../../containers/Agency/NewsletterSection";
import ContactSection from "../../containers/Hosting/Contact";
import getConfig from "next/config";
import { loadCommonProps } from "../../common/next";
import { useTranslation } from "react-i18next";
const NarkumaLandingPage = ({ mailchimpUrl }) => {
	const {
		t
	} = useTranslation("narkuma")
	console.log(t("currentLanguage"));
	return (
		<ThemeProvider theme={saasTheme}>
			<Fragment>
				<Head>
					<title>{t("metaTitle")}</title>
					<meta name="Description" content={t("metaDescription")}/>
					<meta name="theme-color" content="#ec5555"/>
					{/* Load google fonts */ }
					<link
						href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i"
						rel="stylesheet"
					/>
				</Head>
				<ResetCSS />
				<GlobalStyle />
				<ContentWrapper>
					<Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
						<DrawerProvider>
							<Navbar />
						</DrawerProvider>
					</Sticky>
					<BannerSection
						title={{
							...BannerSection.defaultProps.title,
							content: t("banner.title")
						}}
						description={{
							...BannerSection.defaultProps.description,
							content: t("banner.description")
						}}
					/>
					<FeatureSection
						features={[
							{
								id: 1,
								icon: "flaticon-creative",
								title: t("allInOnePackage.title"),
								description: t("allInOnePackage.description")
							},
							{
								id: 2,
								icon: "flaticon-briefing",
								title: t("useFulUtilities.title"),
								description: t("useFulUtilities.description")							},
							{
								id: 3,
								icon: "flaticon-flask",
								title: t("alwaysUpToDate.title"),
								description: t("alwaysUpToDate.description")							}
						]}
					/>
					<VisitorSection
						titleContent={ t("toolsYouNeed.title") }
						descriptionContent={ t("toolsYouNeed.content") }
						buttonContent={ t("joinBeta") }
						image={Narkuma}
					/>
					<ServiceSection
						subtitle={t("toolsWeOffer.title")}
						service={[
							{
								id: 1,
								icon: "flaticon-blockchain",
								title: t("toolsWeOffer.analytics.title"),
								description: t("toolsWeOffer.analytics.description")
							},
							{
								id: 2,
								icon: "flaticon-creative",
								title: t("toolsWeOffer.blog.title"),
								description: t("toolsWeOffer.blog.description")
							},
							{
								id: 3,
								icon: "flaticon-conversation",
								title: t("toolsWeOffer.chat.title"),
								description: t("toolsWeOffer.chat.description")
							},
							{
								id: 4,
								icon: "flaticon-code",
								title: t("toolsWeOffer.code.title"),
								description: t("toolsWeOffer.code.description")
							},
							{
								id: 5,
								icon: "flaticon-atom",
								title: t("toolsWeOffer.crm.title"),
								description: t("toolsWeOffer.crm.description")
							},
							{
								id: 6,
								icon: "flaticon-briefing",
								title: t("toolsWeOffer.email.title"),
								description: t("toolsWeOffer.email.description")
							},
							{
								id: 7,
								icon: "flaticon-group",
								title: t("toolsWeOffer.forum.title"),
								description: t("toolsWeOffer.forum.description")
							},
							{
								id: 8,
								icon: "flaticon-pencil-case",
								title: t("toolsWeOffer.office.title"),
								description: t("toolsWeOffer.office.description")
							},
							{
								id: 9,
								icon: "flaticon-upload",
								title: t("toolsWeOffer.misc.title"),
								description: t("toolsWeOffer.misc.description")
							},
							{
								id: 10,
								icon: "flaticon-startup",
								title: t("toolsWeOffer.more.title"),
								description: t("toolsWeOffer.more.description")
							}
						]}
					/>
					{/*TODO: pricingSection*/}
					{/*<PricingSection/>*/}
					<TestimonialSection
						testimonial={[
							{
								comment: t("testimonial.0.comment"),
								name: t("testimonial.0.name"),
								designation: t("testimonial.0.designation"),
								avatar_url: TeamParallax,
								social_icon: "flaticon-instagram"
							},
							{
								comment: t("testimonial.1.comment"),
								name: t("testimonial.1.name"),
								designation: t("testimonial.1.designation"),
								avatar_url: MWO,
								social_icon: "flaticon-instagram"
							},
							{
								comment: t("testimonial.2.comment"),
								name: t("testimonial.2.name"),
								designation: t("testimonial.2.designation"),
								avatar_url: Fossa,
								social_icon: "flaticon-instagram"
							},
							{
								comment: t("testimonial.3.comment"),
								name: t("testimonial.3.name"),
								designation: t("testimonial.3.designation"),
								avatar_url: Myr,
								social_icon: "flaticon-instagram"
							}
						]}
					/>
					{/*TODO: partnerSection*/}
					{/*<PartnerSection/>*/}
					<TimelineSection
						titleContent={t("howDoesItWork.title")}
						subtitleContent={t("howDoesItWork.content")}
						buttonContent={t("joinBeta")}
						timeline={[
							{
								title: t("howDoesItWork.timeline.0.title"),
								description: t("howDoesItWork.timeline.0.description")
							},
							{
								title: t("howDoesItWork.timeline.1.title"),
								description: t("howDoesItWork.timeline.1.description")
							},
							{
								title: t("howDoesItWork.timeline.2.title"),
								description: t("howDoesItWork.timeline.2.description")
							}
						]}
					/>
					<ContactSection
						mailChimpUrl={mailchimpUrl}
						secText={{
							...ContactSection.defaultProps.secText,
							content: t("joinBeta")
						}}
						secHeading={{
							...ContactSection.defaultProps.secHeading,
							content: t("contentSection.secondary")
						}}
						buttonText={t("contentSection.button")}
					/>
					{/*TODO: FaqSection, TrialSection, Footer*/}
					<FaqSection
						sectionTitle={{
							...FaqSection.defaultProps.sectionTitle,
							content: t("faq.title")
						}}
						button={{
							...FaqSection.defaultProps.button,
							title: "Try our Demo",
						}}
						faqs={[
							{
								id: 1,
								expend: true,
								title: t("faq.entries.0.title"),
								description: t("faq.entries.0.description")
							},
							{
								id: 2,
								expend: true,
								title: t("faq.entries.1.title"),
								description: t("faq.entries.1.description")
							},
							{
								id: 3,
								expend: true,
								title: t("faq.entries.2.title"),
								description: t("faq.entries.2.description")
							},
							//							{
							//								id: 4,
							//								expend: true,
							//								title: "Is app xy available?",
							//								description:
							//									"Check out our demo - there you can see a marketplace "
							//							},
						]}
					/>
					<Footer
					footerData={[
						{
							title: "",
							menuItems: []
						},
						{
							title: "",
							menuItems: []
						},
						{
							title: "",
							menuItems: []
						},
						{
							title: t("footer.imprint.title"),
							menuItems: [
								{
									text: t("footer.imprint.menuItems.0.text")
								},
								{
									text: t("footer.imprint.menuItems.1.text")
								},
								{
									text: t("footer.imprint.menuItems.2.text")
								},
								{
									text: t("footer.imprint.menuItems.3.text")
								}
							]
						},
					]}
					/>
				</ContentWrapper>
			</Fragment>
		</ThemeProvider>
	);
};
export default NarkumaLandingPage;
export const getStaticProps = async ({
	params,
	locale
}) => {
	const {
		mailchimpUrl
	} = getConfig().publicRuntimeConfig;
	return {
		props: {
			...await loadCommonProps(locale),
			mailchimpUrl
		},
	}
}